body {
  background-color: #cee5e3;
  padding: 20px;
  margin: 0;
  font-family: sans-serif;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
b {
	color: #339999;
}
p {
	margin: 3;
}
textarea{
	width:100%;
	height: auto;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.login {
  background-color: #FFF;
  padding: 20px;
  margin: auto;
  width: 400px;
  padding: 10px;
  text-align: center;
  padding-top:50px;
  padding-bottom: 50px;
}
.imagenAbsoluta{
	position: absolute;
	background-color: #fff;
	margin: auto;
  width: 90%;
  height: 75%;
  padding: 10px;
}
.tablaEntrada{
	width:100%;
	border-collapse: collapse;
	text-align: left;
	border: 1px solid black;
	margin:0;
	padding:0;
	border-collapse: collapse;
}
.tablaEntrada table,td,th{
	border: 1px solid black;
	padding:5px;
}
.crono{
	height:50px;
	border: 1px solid black;
	box-sizing: border-box;
	float:left;
}
.crono:hover{
	background-color: #00FF00;
}
.cronoEmpty{
	height:50px;
	background-color:#ccc;
	float:left;
	cursor: auto;
}
.tablaEntrada tr:hover {
	background-color: #339999;
	color: #fff;
	cursor: pointer;
}
.tablaTareas{
	width:100%;
	border-collapse: collapse;
	text-align: left;
	border: 1px solid black;
	margin:0;
	padding:0;
	border-collapse: collapse;
}
.tablaTareas table,td,th{
	border: 1px solid black;
	padding:5px;
}
.tablaLineas {
	width:100%;
	border-collapse: collapse;
	text-align: left;
	border: 1px solid black;
	margin:0;
	padding:0;
	background-color: #CDEEEE;
	border-collapse: collapse;
}
.tablaLineas table,td,th{
	border: 1px solid black;
	padding:5px;
}
.preLogin {
	padding: 15% 0;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #339999;
}

.main {
	padding-top: 40px;
	text-align: center;
}

.fortaLoginLogo {
	height: 40;
	padding-bottom: 40px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.inputLogin{
	width: 100%;
}

.tablaIncidencias th{
	color: #339999;
}
.tablaIncidencias {
	width: 100%;
	padding-top:10px;
}
.tablaIncidencias th{
	text-align:left;
}
.floatRight{
	float: right;
}
.cursorPointer{
	cursor: pointer;
}
.rotateimg180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.divRight{
	width: 50%;
	float: right;
}
.divLeft{
	width: 50%;
	float: left;
}
.gris{
	padding: 30px;
	border-style: groove;
	background-color: #EEE;
}
.loteTable{
	width:100%;
}
.loteTable th{
	text-align: left;
}
.list{
	width:100%;
	background-color:#cee5e3;
	padding:3px;
	border:1px solid grey
}
.listArticulos{
	padding: 0px;
	margin: 0px;
	margin-bottom: 2px;
}
#separador {width:100%;height:1px;padding:0px;margin:0px}